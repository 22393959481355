// src/components/auth/GoogleSignIn.js
import React, { useState } from 'react';
import { Button } from "../ui/button";
import { useAuth } from "../contexts/AuthContext";
import { useToast } from "../ui/use-toast";
import { Loader2 } from "lucide-react";

const GoogleSignIn = ({ 
  onSuccess, 
  onError, 
  className = "", 
  isRegistering = false,
  disabled = false 
}) => {
  const { signInWithGoogle } = useAuth();
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const handleGoogleAuth = async () => {
    if (isLoading) return;

    try {
      setIsLoading(true);
      const user = await signInWithGoogle(isRegistering);
      
      toast({
        title: "Success",
        description: `Successfully ${isRegistering ? 'registered' : 'signed in'} with Google!`,
      });
      
      if (onSuccess) {
        await onSuccess(user);
      }
    } catch (error) {
      console.error("Google auth error:", error);
      
      let errorMessage = "Failed to authenticate with Google";
      switch (error.code) {
        case 'auth/popup-closed-by-user':
          errorMessage = "Sign-in window was closed";
          break;
        case 'auth/cancelled-popup-request':
          errorMessage = "Sign-in process was cancelled";
          break;
        case 'auth/popup-blocked':
          errorMessage = "Sign-in window was blocked by browser";
          break;
        case 'auth/account-exists-with-different-credential':
          errorMessage = "An account already exists with this email";
          break;
        default:
          errorMessage = error.message;
      }
      
      toast({
        title: "Authentication Error",
        description: errorMessage,
        variant: "destructive",
      });
      
      if (onError) {
        onError(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      onClick={handleGoogleAuth}
      variant="outline"
      className={`relative w-full flex items-center justify-center gap-2 ${className}`}
      disabled={disabled || isLoading}
    >
      {isLoading ? (
        <Loader2 className="h-5 w-5 animate-spin" />
      ) : (
        <svg 
          className="h-5 w-5" 
          viewBox="0 0 24 24" 
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#4285F4"
            d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
          />
          <path
            fill="#34A853"
            d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
          />
          <path
            fill="#FBBC05"
            d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
          />
          <path
            fill="#EA4335"
            d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
          />
        </svg>
      )}
      <span>
        {isLoading 
          ? (isRegistering ? 'Registering...' : 'Signing in...') 
          : (isRegistering ? 'Register with Google' : 'Sign in with Google')
        }
      </span>
    </Button>
  );
};

export default GoogleSignIn;
